import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { getCurrentTimeZone } from '@vizsla/utils';
import { useAppAuth } from '@vizsla/hooks';

import { AppRouter } from './providers';
import { ConfirmEmailModal, LoginModal, SignupModal, ForgotPasswordModal } from './modals';

export const App: React.FC = () => {
  const [firstRoute, setFirstRoute] = React.useState<string | null>(null);
  const { location } = window;
  const { isAuthenticated } = useAppAuth();
  getCurrentTimeZone();

  React.useEffect(() => {
    setFirstRoute(location.pathname);
    if (isAuthenticated && firstRoute !== null) {
      window.location.reload();
    }
  }, [isAuthenticated, location.pathname]);

  return (
    <BrowserRouter>
      <AppRouter />
      <LoginModal firstRoute={firstRoute} />
      <SignupModal />
      <ForgotPasswordModal />
      <ConfirmEmailModal />
    </BrowserRouter>
  );
};
