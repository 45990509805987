import styled from '@emotion/styled';
import { Typography } from '@mui/material';

import { CARD_BACKGROUND } from 'src/assets';

/// Form

export const Form = styled.div`
  display: flex;
  flex-direction: column;
`;

/// Actions

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 0.5rem;
  flex-wrap: wrap;

  @media (max-width: 300px) {
    & > button:first-of-type {
      width: 100%;
      margin: 0;
    }
  }
`;

/// Section

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

/// Title

export const Title = styled(Typography)`
  font-weight: bold;
`;

/// Link

export const ExternalLink = styled.a`
  text-decoration: none;
`;

export const PaymentCard = styled.div`
  border-radius: 1rem;
  font-family: monospace;
`;

export const PaymentCardInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  transition: transform 600ms ease;
  transform-style: preserve-3d;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin: 0 auto;
`;

export const PaymentCardFront = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding: 1rem;
  border-radius: 0.6rem;
  overflow: hidden;
  backface-visibility: hidden;
  color: #fff;
  background: #000000;
  background: url(${CARD_BACKGROUND});
  background-size: cover;
`;
export const PaymentCardChip = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 2.5rem;
  top: 0;
  border-radius: 0.3rem;
  background-image: linear-gradient(to bottom left, #ffecc7, #d0b978);
  overflow: hidden;

  @media (max-width: 300px) {
    width: 2rem;
    height: 2rem;
  }

  & > div:nth-of-type(1) {
    top: 13px;
  }
  & > div:nth-of-type(2) {
    top: 20px;
  }
  & > div:nth-of-type(3) {
    top: 28px;
  }
  & > div:nth-of-type(4) {
    left: 25px;
    width: 1px;
    height: 50px;
  }
`;
export const PaymentCardChipLine = styled.div`
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #303030;
`;
export const PaymentCardChipMain = styled.div`
  width: 1.25rem;
  height: 1.45rem;
  border: 1px solid #333;
  border-radius: 0.1875rem;
  background-image: linear-gradient(to bottom left, #efdbab, #e1cb94);
  z-index: 1;

  @media (max-width: 600px) {
    width: 1em;
  }
`;
export const PaymentCardNumber = styled.div`
  display: flex;
  margin-top: auto;
  gap: 1.75rem;
  font-size: 1rem;

  @media (max-width: 600px) {
    gap: 1.4rem;
  }

  @media (max-width: 300px) {
    flex-wrap: wrap;
    gap: 1rem;
    font-size: 0.9rem;
  }
`;
export const PaymentCardNumberSection = styled.div``;

export const PaymentCardInfoBottom = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
export const PaymentCardHolder = styled(Typography)`
  text-transform: uppercase;
`;

export const PaymentCardHolderInfo = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
`;
export const PaymentCardExpDate = styled(Typography)``;
export const PaymentLogo = styled.div`
  display: flex;
`;
