import React, { FC, useCallback, useState, useMemo } from 'react';
import { Form } from 'react-final-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Grid, InputAdornment, Typography } from '@mui/material';
import { LockOutlined, MailOutlined } from '@mui/icons-material';

import { useTicketingVerifyUserLazyQuery } from '@vizsla/graphql';
import { Field, Modal, TextField } from '@vizsla/components';
import { PaletteColor } from '@vizsla/theme';
import { useAppAuth, useModal } from '@vizsla/hooks';

import { AuthSubmitButton } from 'src/components/auth';
import { MODALS } from 'src/constants/modals';
import { VerifyUser } from 'src/modals';

const useStyles = makeStyles({
  modalWrapper: {
    '& .MuiDialog-container': {
      justifyContent: 'center',
    },
    '& .MuiDialog-paper': {
      width: '400px',
      maxWidth: '600px',
      maxHeight: 'unset',
      margin: 0,
      padding: '1%',
    },
    '& .MuiPaper-root': {
      display: 'block',
    },
    '& .MuiDialogContent-root': {
      overflowY: 'auto',
      scrollbarWidth: 'auto',
      maxHeight: '50vh',
    },
  },
  login: {
    zIndex: '9999999',
  },
  signupText: {
    color: 'black',
  },
  textContainer: {
    margin: 'auto !important',
  },
  inputFont: {
    '& input': {
      fontSize: 'clamp(12px, 2vw, 16px)',
    },
  },
});

interface LoginModalProps {
  firstRoute: string | null;
}

export const LoginModal: FC<LoginModalProps> = ({ firstRoute }) => {
  const [email, setEmail] = useState('');
  const [showVerifyUser, setVerifyUser] = useState(false);
  const classes = useStyles();
  const verifyEmail = /\S+@\S+\.\S+/;

  const { isAuthenticated } = useAppAuth();
  const { isOpen, closeModal, openModal, args } = useModal(MODALS.LOGIN_MODAL);
  const { closeModal: closeVerifyUser, openModal: openVerifyUSer } = useModal();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { login } = useAppAuth();

  const route = window.location.pathname;

  const segments = route.split('/');
  const indexParameterId =
    segments.findIndex(index => index === 'experience' || index === 'campaign') + 1;
  const experienceId = indexParameterId > 0 ? segments[indexParameterId] : null;

  const [verifyTicketingUser, { loading }] = useTicketingVerifyUserLazyQuery();

  const url = useMemo(() => {
    if (args && args.path) {
      return args.path;
    }
    return undefined;
  }, [args]);

  const onCreateAccount = (reset: (initialValues?: Partial<any> | undefined) => void) => {
    reset();
    closeModal();
    openModal(MODALS.SIGNUP_MODAL, args);
  };

  const onForgotPassword = React.useCallback(
    reset => {
      reset();
      closeModal();
      openModal(MODALS.FORGOT_PASSWORD_MODAL, args);
    },
    [closeModal, openModal, args],
  );
  const enableAccount = React.useCallback(
    reset => {
      reset();
      closeModal();
      openModal(MODALS.CONFIRM_EMAIL_MODAL, args);
    },
    [closeModal, openModal, args],
  );

  const onChange = data => {
    if (verifyEmail.test(data.target.value)) {
      setEmail(data.target.value);
    }
  };

  const onSubmit = useCallback(
    async data => {
      const onCloseVerifyUser = () => {
        setVerifyUser(false);
        closeVerifyUser();
      };

      try {
        const { data: verifyUser } = await verifyTicketingUser({
          variables: {
            experienceId: experienceId as string,
            email: data.email,
          },
        });

        if (experienceId !== null && verifyUser && !loading) {
          if (!verifyUser.ticketingVerifyUser?.success) {
            setVerifyUser(true);
            closeModal();
            openVerifyUSer(MODALS.VERIFY_USER, {
              warning: verifyUser.ticketingVerifyUser?.message,
              verifyUser: onCloseVerifyUser,
            });
            return window.open(url ?? pathname, '_self');
          }
          await login(data);
          closeModal();
          args?.onComplete?.();
          if (args?.onComplete) {
            return navigate(url ?? pathname);
          }
          return window.open(url ?? pathname, '_self');
        }

        await login(data);
        closeModal();
        args?.onComplete?.();
        if (args?.onComplete) {
          return navigate(url ?? pathname);
        }
        return window.open(url ?? pathname, '_self');
      } catch (e) {
        return {
          email: 'Incorrect email or password',
          password: 'Incorrect email or password',
        };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [args, closeModal, login, navigate, pathname, loading],
  );

  const onClose = () => {
    // if (!isAuthenticated && firstRoute) {
    //   navigate(firstRoute);
    // }
    closeModal();
    args?.onClose?.();
  };

  return (
    <React.Fragment>
      {!showVerifyUser ? (
        <Modal isOpen={isOpen} onClose={onClose} customClasses={[classes.modalWrapper]}>
          <Modal.Title>Login to Your Account</Modal.Title>
          <Modal.Content>
            <Form onSubmit={onSubmit}>
              {({ handleSubmit, submitting, pristine, hasValidationErrors, form }) => {
                const isDisabled = submitting || pristine || hasValidationErrors;
                return (
                  <form onSubmit={handleSubmit} onChange={onChange}>
                    <Grid container direction="column" spacing={3}>
                      <Grid item xs={12} paddingLeft={0}>
                        <Grid
                          container
                          spacing={0}
                          margin={0}
                          xs={12}
                          justifyContent="center"
                          direction="column"
                        >
                          <Grid item marginTop={2} marginBottom={2}>
                            <Typography variant="body1">Login with Email</Typography>
                          </Grid>
                          <Grid item paddingTop={1}>
                            <Field
                              name="email"
                              label="Email Address"
                              component={TextField}
                              e2e-id="email"
                              InputProps={{
                                placeholder: 'Email Address',
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <MailOutlined />
                                  </InputAdornment>
                                ),
                              }}
                              className={classes.inputFont}
                              fullWidth
                            />
                          </Grid>
                          <Grid item paddingTop={2}>
                            <Field
                              name="password"
                              label="Password"
                              component={TextField}
                              type="password"
                              e2e-id="password"
                              InputProps={{
                                placeholder: 'Password',
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <LockOutlined />
                                  </InputAdornment>
                                ),
                              }}
                              className={classes.inputFont}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} paddingLeft={0}>
                        <AuthSubmitButton
                          type="submit"
                          loading={submitting}
                          disabled={isDisabled}
                          e2e-id="submit"
                        >
                          Login
                        </AuthSubmitButton>
                      </Grid>
                      <Grid
                        display="flex"
                        flexWrap="wrap"
                        item
                        xs={12}
                        className={classes.textContainer}
                      >
                        <Typography className={classes.signupText} component="span">
                          Don&apos;t have an Account?&nbsp;
                        </Typography>
                        <Typography
                          style={{ color: PaletteColor.TealText, cursor: 'pointer' }}
                          component="span"
                          onClick={() => onCreateAccount(form.reset)}
                        >
                          Create Account
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        display="flex"
                        flexWrap="wrap"
                        direction="row"
                        paddingLeft={5}
                        spacing={1}
                        marginTop={1}
                      >
                        <Grid
                          item
                          xs={12}
                          display="flex"
                          flexDirection="row"
                          className={classes.textContainer}
                        >
                          <Typography
                            style={{ color: PaletteColor.TealText, cursor: 'pointer' }}
                            component="span"
                            onClick={() => onForgotPassword(form.reset)}
                          >
                            Forgot Password?
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          display="flex"
                          flexDirection="row"
                          className={classes.textContainer}
                        >
                          <Typography
                            style={{ color: PaletteColor.TealText, cursor: 'pointer' }}
                            component="span"
                            onClick={() => enableAccount(form.reset)}
                          >
                            Resend Access Code
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                );
              }}
            </Form>
          </Modal.Content>
        </Modal>
      ) : (
        <VerifyUser />
      )}
    </React.Fragment>
  );
};
