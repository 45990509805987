import React from 'react';
import _ from 'lodash';

import { Cart, CartCategory, CartItem } from 'src/types/cart';

interface CartContextType {
  cart: Cart;
  getCartItem: <I extends CartItem, C extends Cart>(
    category: CartCategory<C>,
    id: string,
  ) => I | null;
  upsertCartItem: <I extends CartItem, C extends Cart>(category: CartCategory<C>, item: I) => void;
  updateCategory: <I extends CartItem, C extends Cart>(
    category: CartCategory<C>,
    items: Record<string, I>,
  ) => void;
  deleteCartItem: <C extends Cart>(category: CartCategory<C>, id: string) => void;
  getIdsFromCategory: <C extends Cart>(category: CartCategory<C>) => Array<string>;
  resetCart: () => void;
}

const initialCart: CartContextType = {
  cart: {} as Cart,
  getCartItem: () => null,
  upsertCartItem: () => {},
  updateCategory: () => {},
  deleteCartItem: () => {},
  getIdsFromCategory: () => [],
  resetCart: () => {},
};

export const CartContext = React.createContext<CartContextType>(initialCart);

export const CartProvider: React.FC = ({ children }) => {
  const [cart, setCart] = React.useState<Cart>({});

  const getCartItem = React.useCallback(
    <I extends CartItem, C extends Cart>(category: CartCategory<C>, id: string): I | null => {
      return _.get(cart, [category, id], null) as I | null;
    },
    [cart],
  );

  const upsertCartItem = React.useCallback(
    <I extends CartItem, C extends Cart>(category: CartCategory<C>, item: I) => {
      setCart(cart => {
        const itemsFromCategory = _.get(cart, [category], {});

        return {
          ...cart,
          [category]: {
            ...itemsFromCategory,
            [item.id]: item,
          },
        };
      });
    },
    [],
  );

  const updateCategory = React.useCallback(
    <I extends CartItem, C extends Cart>(category: CartCategory<C>, items: Record<string, I>) => {
      setCart(cart => ({
        ...cart,
        [category]: {
          ...items,
        },
      }));
    },
    [],
  );

  const deleteCartItem = React.useCallback(
    <C extends Cart>(category: CartCategory<C>, id: string) => {
      setCart(cart => {
        const itemsFromCategory = _.get(cart, [category], {} as Record<string, CartItem>);
        const { [id]: deletedItem, ...newItemsFromCategory } = itemsFromCategory;

        return {
          ...cart,
          [category]: newItemsFromCategory,
        };
      });
    },
    [],
  );

  const getIdsFromCategory = React.useCallback(
    <C extends Cart>(category: CartCategory<C>) => {
      return _.keys(_.get(cart, [category], null));
    },
    [cart],
  );

  const resetCart = React.useCallback(() => {
    setCart({});
  }, []);

  return (
    <CartContext.Provider
      value={{
        cart,
        getCartItem,
        upsertCartItem,
        updateCategory,
        deleteCartItem,
        getIdsFromCategory,
        resetCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
