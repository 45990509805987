import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Checkbox, Grid, Skeleton, Typography } from '@mui/material';
import _ from 'lodash';

import {
  selectAllRegistrations,
  selectAllSwagBags,
  useShoppingCartState,
  useNotification,
} from '@vizsla/hooks';
import {
  AssetSwagbagList,
  useGetAllFileListQuery,
  useSwagBagListByRegistrationOptionQuery,
} from '@vizsla/graphql';
import { Form } from '@vizsla/components';
import { FontSize } from '@vizsla/theme';
import { CartItem, ShoppingCartData } from '@vizsla/types';
import { getUserFullName } from '@vizsla/utils';

import { ExperiencePurchaseLayout } from 'src/layouts';
import { useCurrentExperience, useOpenExperiencePages } from 'src/hooks';

import { Button, Buttons, Content } from './ExperienceSwagBagStep.style';
import { SwagBagName } from './SwagBagName';

type CustomAssetType = AssetSwagbagList & {
  uri: string | undefined | null;
  checked: boolean;
  email: string;
  userName?: string;
};

function validateSelectedSwagbagsInCart(finalData, cartItems) {
  const filteredData = finalData.filter(item => 'id' in item);

  const regEmails = [...new Set(filteredData.map(item => item.email))];

  for (const email of regEmails) {
    // Check if the email exists at least once in the cart items

    const emailExistsInCart = cartItems.some(item => item.swagBag && item.swagBag.email === email);

    if (!emailExistsInCart) {
      return false;
    }
  }

  return true;
}

export function ExperienceSwagBagStep() {
  const { experienceId } = useParams();

  const { registrations, swagBags, optionIds, addItem, removeItem } = useShoppingCartState(
    state => {
      const registrations = selectAllRegistrations(state);
      const swagBags = selectAllSwagBags(state);
      return {
        registrations,
        swagBags,
        optionIds: registrations.map(item => item.option.id),
        addItem: state.add,
        removeItem: state.remove,
      };
    },
  );

  const { experience: initialExperience } = useCurrentExperience();

  const {
    openSelectTeam,
    openAssetsForm,
    openRegistrationForm: openOptionsSelector,
  } = useOpenExperiencePages();

  const { data, loading: loadingAssets } = useSwagBagListByRegistrationOptionQuery({
    variables: {
      filter: {
        registrationOptionId: {
          in: optionIds,
        },
      },
    },
  });

  React.useEffect(() => {
    if (data) {
      if (data?.assets?.count === 0) {
        openAssetsForm(experienceId);
      }
    }
  }, [data]);

  const notification = useNotification();

  if (initialExperience?.experienceType === 'Ticketing') {
    openAssetsForm(experienceId);
  }

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const assets = React.useMemo(() => {
    const items = data?.assets?.items ?? [];

    const assets = items.filter(item => {
      if (item?.continueSalesWhenOutOfStock) {
        return true;
      }
      return false;
    });

    return assets;
  }, [data]);

  const pictureIds = React.useMemo(() => {
    return assets.map(asset => asset.picture as string);
  }, [assets]).filter(item => item);

  const { data: assetsPictures, loading: loadAssetsPictures } = useGetAllFileListQuery({
    variables: { filter: { id: { in: pictureIds } } },
  });

  const loading = loadingAssets || loadAssetsPictures;

  const picturesData = React.useMemo(() => {
    return assetsPictures?.filesList?.items || [];
  }, [assetsPictures]);

  const validateCheck = (asset: AssetSwagbagList, email: string) => {
    const swagBagOption = swagBags.find(
      item => item.swagBag.inventoryId === asset?.inventoryId && item.swagBag.email === email,
    );

    return Boolean(swagBagOption);
  };

  const finalData: CustomAssetType[] = useMemo(() => {
    const filteredAssets: CustomAssetType[] = [];
    const emailsRegistered: string[] = [];

    registrations.forEach(cartOption => {
      assets.forEach(asset => {
        const image = picturesData.filter(itF => itF.id === asset.picture);

        if (asset.registrationOptionId === cartOption.option.id) {
          filteredAssets.push({
            ...asset,
            uri: image[0]?.downloadUrl,
            checked: validateCheck(asset, cartOption?.attendee?.email ?? ''),
            email: cartOption?.attendee?.email ?? '',
            userName: getUserFullName(cartOption?.attendee),
          });
        }
      });
      emailsRegistered.push(cartOption?.attendee?.email ?? '');
    });

    emailsRegistered.forEach(emailRegistered => {
      const isEmailInFilteredAssets = filteredAssets.some(asset => asset.email === emailRegistered);
      if (!isEmailInFilteredAssets) {
        const selectedReg = registrations.filter(reg => reg.attendee?.email === emailRegistered);
        filteredAssets.push({
          uri: '',
          checked: false,
          userName: getUserFullName(selectedReg[0].attendee),
          email: emailRegistered,
          variantsValues: {
            Size: '',
            Style: '',
          },
        });
      }
    });
    return filteredAssets;
  }, [assets, picturesData]);

  React.useEffect(() => {
    setMappedData(finalData);
  }, [finalData]);

  // State to manage the mapped data
  const [mappedData, setMappedData] = React.useState<CustomAssetType[]>([]);

  const assetCount = (optionId: string) => {
    return swagBags.filter(item => item.swagBag.id === optionId).length;
  };

  const addAsset = (data: typeof finalData[number]) => {
    if (!data || !data?.id) {
      return;
    }

    addItem({
      type: 'swagBag',
      name: data.name as string,
      // Swag-bags are always free.
      price: 0,
      swagBag: {
        id: data.id,
        assetId: data.assetId as string,
        inventoryId: data.inventoryId as string,
        optionId: data.registrationOptionId ?? undefined,
        pictureId: data.picture as string,
        name: data.name as string,
        uri: data.uri as string,
        variants: data.variantsValues,
        email: data.email,
      },
    });

    assetCount(data?.id);
  };

  const removeAsset = (asset: typeof finalData[number]) => {
    const assetToBeRemoved = swagBags.find(
      assetItem =>
        assetItem.swagBag.id === asset.id &&
        assetItem.swagBag.email === asset.email &&
        assetItem.swagBag.optionId === asset.registrationOptionId,
    );
    if (asset?.id) {
      removeItem({ id: assetToBeRemoved?.id ?? '' });
    }
  };

  const removeAssetFromShoppingCart = (asset: CartItem<ShoppingCartData>) => {
    if (asset) {
      const data = swagBags;

      const assetFound = data.find(
        item =>
          item.type === 'swagBag' &&
          asset.type === 'swagBag' &&
          item.swagBag.pictureId === asset?.swagBag.pictureId &&
          item.swagBag.optionId === asset?.swagBag?.optionId &&
          item.swagBag.assetId === asset.swagBag.assetId &&
          item.swagBag.email === asset.swagBag.email,
      );

      removeItem({ id: assetFound?.id ?? '' });
    }
  };

  interface SwagBagItemProps {
    assetItem: CustomAssetType;
    setMappedData: React.Dispatch<React.SetStateAction<CustomAssetType[]>>;
  }

  const SwagBagItem: React.FC<SwagBagItemProps> = (props: SwagBagItemProps) => {
    const handleChange = (asset: typeof finalData[number]) => {
      const isAlreadySelected = swagBags.find(
        item =>
          item.type === 'swagBag' &&
          item.swagBag.assetId === asset.assetId &&
          // With this condition, It's checked if is the same item or not selected/deselected
          item.swagBag.id !== asset.id &&
          item.swagBag.email === asset.email,
      );

      if (isAlreadySelected) {
        removeAssetFromShoppingCart(isAlreadySelected);
        addAsset(asset);
      } else if (asset.checked) {
        removeAsset(asset);
      } else {
        addAsset(asset);
      }

      if (isAlreadySelected) {
        const selectedId = isAlreadySelected.type === 'swagBag' && isAlreadySelected?.swagBag.id;

        props.setMappedData(prevData =>
          prevData.map(item =>
            item.id === selectedId && item.email === asset.email
              ? { ...item, checked: false }
              : item,
          ),
        );
      }
      props.setMappedData(prevData =>
        prevData.map(item =>
          item.id === asset.id && item.email === asset.email
            ? { ...item, checked: !item.checked }
            : item,
        ),
      );
    };
    return (
      <Grid
        id={props?.assetItem.id + props.assetItem.email + props.assetItem.registrationOptionId}
        key={props?.assetItem.id + props.assetItem.email + props.assetItem.registrationOptionId}
        container
        spacing={2}
        xs={12}
        flexDirection="row"
        mb="40px"
        item
        pl="10px"
        pr="10px"
        data-test="swagbagItem"
        className="swagbagItem"
      >
        <SwagBagName storeItem={props.assetItem} />

        <Grid justifyContent="center" alignItems="center" display="flex">
          <Checkbox
            checked={props.assetItem.checked}
            onChange={() => handleChange(props.assetItem)}
          />
        </Grid>
      </Grid>
    );
  };

  const renderListSwagBag = () => {
    const groupedData = _.groupBy(mappedData, 'email');

    return (
      <Grid item xs={12} data-test="swagbagContainer" className="swagbagContainer">
        <Typography variant="body1" align="left" fontWeight={700} fontSize={FontSize.XXL}>
          Swag Bag Selection
        </Typography>
        {Object.keys(groupedData).length === 0 ? (
          <Typography variant="body1" align="left" pb={4} fontSize={FontSize.M}>
            There are no swag bags set for this experience.
          </Typography>
        ) : (
          <Typography variant="body1" align="left" pb={4} fontSize={FontSize.M}>
            Please choose at least one item for each attendee to complete your swag bag selection.
          </Typography>
        )}
        <Grid item>
          {Object.keys(groupedData).map(email => {
            const assetItems = groupedData[email];

            return (
              <Grid key={email}>
                <Typography
                  variant="body1"
                  align="left"
                  pb={4}
                  fontWeight={600}
                  fontSize={FontSize.L}
                >
                  Attendee: {groupedData[email][0]?.userName}
                </Typography>

                {!assetItems[0].id ? (
                  <Typography variant="body1" align="left" pb={4} fontSize={FontSize.M}>
                    There are no swag bags set for this attendee.
                  </Typography>
                ) : (
                  assetItems.map(assetItem => {
                    return SwagBagItem({ assetItem, setMappedData });
                  })
                )}
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    );
  };

  const handleStartOver = () => {
    openOptionsSelector(experienceId);
  };

  const handleBack = () => {
    openSelectTeam(experienceId);
  };

  const handleSubmit = () => {
    const isAtLeastOneSwagBagSelectedPerAttendee = validateSelectedSwagbagsInCart(
      finalData,
      swagBags,
    );

    if (finalData.length === 0) {
      openAssetsForm(experienceId);
    } else if (isAtLeastOneSwagBagSelectedPerAttendee) {
      openAssetsForm(experienceId);
    } else {
      notification.warning('You have to select at least one swagbag item for each attendee');
    }
  };

  return (
    <ExperiencePurchaseLayout>
      <Form onSubmit={handleSubmit}>
        {form => (
          <Content>
            {loading && (
              <Grid mb={5}>
                <Skeleton variant="rectangular" height={48} />
                <Skeleton variant="rectangular" height={48} />
                <Skeleton variant="rectangular" height={48} />
              </Grid>
            )}

            {!loading && renderListSwagBag()}

            <Buttons>
              <Button variant="outlined" onClick={handleStartOver}>
                Start Over
              </Button>

              <Button variant="outlined" onClick={handleBack}>
                Back
              </Button>

              <Button onClick={form.handleSubmit} disabled={form.invalid}>
                Continue
              </Button>
            </Buttons>
          </Content>
        )}
      </Form>
    </ExperiencePurchaseLayout>
  );
}
